<div class="main-container padding-top-4" fxLayout="column" fxLayoutAlign="start start">
  <div class="image-container">
    <img style="width: 100%;"src="{{showcaseItem.productSkus[0].images | getImagePathByType:'THUMBNAIL'}}">
  </div>

  <div class="title-container" fxLayout ="row" fxLayoutAlign="space-between center">
    <div class="left-container" >
      {{showcaseItem.productSkus[0].productSkuEavs | getValueByAttribute:'name':'value'}}
    </div>

    <div class="right-container"fxLayout ="row" fxLayoutAlign="start start" >
      <div class="price" [ngClass]="{'deleted': isSalesPrice(showcaseItem.productSkus[0])}">
         ${{showcaseItem.productSkus[0].price}}
      </div>
      <div *ngIf="isSalesPrice(showcaseItem.productSkus[0])" class="sales-price">
        ${{showcaseItem.productSkus[0] | getProductSalesPriceIfAny}}
      </div>
    </div>
  </div>

  <div class="bottom-container" fxLayout ="row" fxLayoutAlign="space-between start">
    <div class="quantity-button-container" fxFlex="45">
      <app-quantity-button
        (addQuantityEvent)="onAddQuantity($event)"
        (subtractQuantityEvent)="onSubstractQuantity($event)"
        (keyEvent)="onKey($event)"
        [selectedQuantity]="selectedQuantity"
      ></app-quantity-button>
    </div>
    <div *ngIf="!isOutOfStock(showcaseItem.productSkus[0]); else outOfStockBlock" (click)="addToCart(showcaseItem.productSkus[0])" class="add-to-cart"fxLayout ="row" fxLayoutAlign="center center" fxFlex="50">
        <a>加入購物籃</a>
        <img src="assets/image/icon/add-to-cart-icon.png">
    </div>
    <ng-template #outOfStockBlock>
      <div class="add-to-cart"fxLayout ="row" fxLayoutAlign="center center" fxFlex="55">
          <a>暫時缺貨</a>
          <img src="assets/image/icon/add-to-cart-icon.png">
      </div>
    </ng-template>
    
  </div>

  <div (click)="popup()" [ngStyle]="{'opacity': isPartySet(showcaseItem.productSkus[0]) ? '1' : '0'}" class="more">
    <span>內容</span>
  </div>

</div>
