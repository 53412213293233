import { Component, OnInit , HostListener} from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { SubscribeUsService } from '@shared/services/subscribe-us.service';


@Component({
  selector: 'app-bottom-footer',
  templateUrl: './bottom-footer.component.html',
  styleUrls: ['./bottom-footer.component.scss']
})
export class BottomFooterComponent implements OnInit {
  email: string

  isMobile=false;

  isMobileMini=false;
  constructor(public breakpointObserver: BreakpointObserver, private subscribeUsService: SubscribeUsService) { }

  year: number = new Date().getFullYear()

  ngOnInit(): void {
    this.breakpointObserver.observe(['(min-width: 992px)']).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    });
  }

  subscribeUs() {
    this.subscribeUsService.sendSubscribeUsEmail({
      email: this.email
    }).subscribe(rsp => {
      console.log('subscribe us response', rsp)
    })
  }

}
