<div *ngIf="show" class="product-popup-container" fxLayout="row" fxLayoutAlign="center center">
    <div class="party-set-container">
        <div class="title">{{productSku.productSkuEavs | getValueByAttribute:'name':'value'}}</div>
        <div fxLayout="row wrap" fxLayoutAlign="center start" class="content">
            <img [src]="productSku.images | getImagePathByType:'THUMBNAIL'" alt="">
            <div class="component">
                <div *ngFor="let name of components">{{name}}</div>
            </div>
        </div>
        <img class="cross" (click)="close()" src="assets/image/icon/cross.png" alt="">
    </div>
</div>
