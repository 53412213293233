import { Component, OnInit } from '@angular/core';
import { ContactUsRequest } from '@shared/models/request/contact-us-request.model'
import { ContactUsService } from '@shared/services/contact-us.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  contactUsRequest: ContactUsRequest = new ContactUsRequest()

  constructor(private contactUsService: ContactUsService) { }

  ngOnInit(): void {
  }

  sendContactUsEmail() {
    console.log('this.contactUsRequest', this.contactUsRequest)
    this.contactUsService.sendContactUsEmail(this.contactUsRequest).subscribe(rsp => {
      console.log("rsp", rsp)
    })
  }

}
