import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-checkout-quantity-button',
  templateUrl: './checkout-quantity-button.component.html',
  styleUrls: ['./checkout-quantity-button.component.scss']
})
export class CheckoutQuantityButtonComponent implements OnInit {

  constructor() { }

  @Input() selectedQuantity: number;

  @Output() addQuantityEvent = new EventEmitter<any>();

  @Output() subtractQuantityEvent = new EventEmitter<any>();

  onAddQuantity($event){
    console.log($event)
    console.log(this.addQuantityEvent)
    this.addQuantityEvent.emit($event)
  }
  onSubstractQuantity($event){
    console.log($event)
    console.log(this.subtractQuantityEvent)
    this.subtractQuantityEvent.emit($event)
  }

  ngOnInit(): void {
  }

}
