export class ProductSkuFilterRequest {
    priceFrom: string = '0';
    priceTo: string = '999999';
    attributes: IFilterAttribute[] = [];
    categoryIds: number[] = [];
    discount: boolean = false;
    baseCategoryId:number;
    routeCategoryId:number;

    addFilterCategory(id){
        this.categoryIds.push(id);
    }
    addAttributes(attribute){
        this.attributes.push(attribute);
    }
    mapFiltered(filter): void{
        this.attributes=[];
        this.categoryIds=[];
        for (const attribute of filter){
            if(attribute.attribute!="subcategory"){
                if(!this.attributes.includes(attribute)) {
                    this.attributes.push(attribute);
                }
            }
            else{
                if(!this.categoryIds.includes(attribute.id)) {
                    this.categoryIds.push(attribute.id);
                }
            }
        }
        if(this.categoryIds.length<1){
            this.categoryIds.push(this.baseCategoryId);
        }
    }

}

export interface IFilterAttribute {
    attribute: string;
    value: string;
}

