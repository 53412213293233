import { ProductSkuEav } from "./product-sku-eav.model";
import { ProductImage } from './product-image.model';
import {CartItem} from "./cart-item.model";

export class ProductSku {
    id: number;
    sku: string;
    createdAt: string;
    createdBy: string;
    updatedAt: string;
    updatedBy: string;
    attribute: string;
    value: string;
    lang: string;
    isDefault: boolean;
    status: string;
    visibility: string;
    productCode: string;
    salesFrom: number;
    salesTo: number;
    currency: string;
    price: string;
    salesPrice: string;
    stock: number;
    minStockLevel: number;
    productSkuEavs: ProductSkuEav[];
    images: ProductImage[];


    /**
     * Constructor
     *
     * @param ShoppingCart
     */
    constructor(productSku?) {
        productSku = productSku || {};
        this.id = productSku.id || 0;
        this.createdAt = productSku.createdAt || '';
        this.createdBy = productSku.createdBy || '';
        this.updatedAt = productSku.updatedAt || '';
        this.updatedBy = productSku.attribute || '';
        this.value = productSku.value || '';
        this.lang = productSku.lang || '';
        this.isDefault = productSku.isDefault || false;
        this.status = productSku.status || '';
        this.visibility = productSku.visibility || '';
        this.productCode = productSku.productCode || '';
        this.salesFrom = productSku.salesFrom || 0;
        this.salesTo = productSku.salesTo || 0;
        this.currency = productSku.currency || '';
        this.price = productSku.price || '';
        this.salesPrice = productSku.salesPrice || '';
        this.stock = productSku.stock || '';
        this.minStockLevel = productSku.number || 0;
        this.productSkuEavs = [];
        if(productSku.productSkuEavs !=null) {
            productSku.productSkuEavs.forEach((element)=>{this.productSkuEavs.push(new ProductSkuEav(element))});
        }
        this.images = [];
        if(productSku.images!=null) {
            productSku.productSkuEavs.forEach((element)=>{this.images.push(new ProductImage(element))});
        }

    }

}
