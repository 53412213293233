<ng-container *ngIf="isMobile == false && hitFooter==false">
  <div #cartIcon class="ellipse" (click)="goToShoppingCart()">
    <img src="assets/image/icon/shopping-bag-icon.svg">
  </div>
</ng-container>


<ng-container *ngIf="isMobile == false && hitFooter==true">
  <div #cartIcon class="ellipse-absolute" (click)="goToShoppingCart()">
    <img src="assets/image/icon/shopping-bag-icon.svg">
  </div>
</ng-container>


<ng-container *ngIf="isMobile == true && hitFooterMobile==false">
  <div #cartIcon class="ellipse" (click)="goToShoppingCart()">
    <img src="assets/image/icon/shopping-bag-icon.svg">
  </div>
</ng-container>


<ng-container *ngIf="isMobile == true && hitFooterMobile==true">
  <div #cartIcon class="ellipse-absolute" (click)="goToShoppingCart()">
    <img src="assets/image/icon/shopping-bag-icon.svg">
  </div>
</ng-container>
