<table *ngIf="cartItems && cartItems.length > 0 && !isMobile" class="shopping-cart-table">
    <tr>
        <th>產品</th>
        <th>價格</th>
        <th>數量</th>
        <th>小計</th>
        <th></th>
    </tr>
    <tr *ngFor="let item of cartItems">
        <td class="product-image">
            <div fxLayout="row" fxLayoutAlign="start center">
                <img class="mr-2" src="{{item.productSku.images | getImagePathByType:'THUMBNAIL'}}">
                <span>{{item.productSku.productSkuEavs | getValueByAttribute:'name':'value'}}</span>
            </div>
        </td>
        <td class="price">
            $ {{item.productSku.price | number}}
        </td>
        <td class="quantity">
            <app-checkout-quantity-button 
                [selectedQuantity]="item.quantity" 
                (addQuantityEvent)="onAddQuantity($event, item)"
                (subtractQuantityEvent)="onSubstractQuantity($event, item)">
            </app-checkout-quantity-button>
        </td>
        <td class="subTotal">
            $ {{item.grandTotal | number}}
        </td>
        <td class="delete">
            <img (click)="removeCartItem(item)" src="assets/image/icon/cross.png" alt="">
        </td>
    </tr>
</table>

<div *ngIf="isMobile" class="shopping-cart-table-mobile">
    <div *ngFor="let item of cartItems" fxLayout="row" fxLayoutAlign="space-between center" class="cart-item-container pb-1">
        <div fxLayout="row" fxLayoutAlign="start center" class="w-100">
            <img class="product-image" src="{{item.productSku.images | getImagePathByType:'THUMBNAIL'}}" alt="">
            <div fxLayout="column" fxLayoutAlign="start start" class="w-100 ml-1">
                <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100 mb-1 mobile-table-row">
                    <div class="product-name">{{item.productSku.productSkuEavs | getValueByAttribute:'name':'value'}}</div>
                    <img (click)="removeCartItem(item)" src="assets/image/icon/cross.png" alt="">
                </div>
                <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100 pd-veritcal-1 mobile-table-row">
                    <div>價格</div>
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <div class="price" [ngClass]="{'deleted': isSalesPrice(item.productSku)}">$ {{item.productSku.price | number}}</div>
                        <div class="sales-price ml-1" *ngIf="isSalesPrice(item.productSku)">$ {{item.grandTotal | number}}</div>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100 pt-1 mobile-table-row">
                    <div>數量</div>
                    <app-checkout-quantity-button 
                        [selectedQuantity]="item.quantity" 
                        (addQuantityEvent)="onAddQuantity($event, item)"
                        (subtractQuantityEvent)="onSubstractQuantity($event, item)">
                    </app-checkout-quantity-button>
                </div>
            </div>
        </div>
    </div>
</div>

