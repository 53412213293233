import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, Input, OnInit } from '@angular/core';
import { CartItem } from '@shared/models/cart-item.model';
import { ProductSku } from '@shared/models/product-sku.model';
import { ShoppingCart } from '@shared/models/shopping-cart.model';
import { CartService } from '@shared/services/cart.service';

@Component({
  selector: 'app-shopping-cart-table',
  templateUrl: './shopping-cart-table.component.html',
  styleUrls: ['./shopping-cart-table.component.scss']
})
export class ShoppingCartTableComponent implements OnInit {

  @Input() cartItems: Array<CartItem>

  isMobile = false;

  constructor(private cartService: CartService, public breakpointObserver: BreakpointObserver) { }

  ngOnInit(): void {
    this.breakpointObserver.observe(['(min-width: 700px)']).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    });
  }

  onAddQuantity($event, item){
    item.quantity += 1
    this.cartService.updateCart(item).subscribe((data: ShoppingCart) => {
      console.log('onItemQtyChange data', data)
      this.cartItems = data.cartItems
      this.cartService.setCart(data)
    })
  }

  onSubstractQuantity($event, item){
    if(item.quantity <= 1) {
      this.removeCartItem(item)
      return
    }
    item.quantity -= 1
    this.cartService.updateCart(item).subscribe((data: ShoppingCart) => {
      console.log('onItemQtyChange data', data)
      this.cartItems = data.cartItems
      this.cartService.setCart(data)
    })
  }

  removeCartItem(item: CartItem) {
    this.cartService.deleteCartItem(item).subscribe((data: ShoppingCart) => {
      console.log('removeCartItem data', data)
      this.cartItems = data.cartItems
      this.cartService.setCart(data)
    })
  }

  isSalesPrice(productSku: ProductSku): boolean {
    if(productSku.salesFrom && productSku.salesTo && productSku.salesPrice) {
      let today = new Date().getTime() / 1000
      if(today > productSku.salesFrom && today < productSku.salesTo) {
        return true;
      }else {
        return false;
      }
    }else {
      return false;
    }
  }

}
