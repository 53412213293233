import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { CommonService } from '../../services/common.service'
import {  ActivatedRoute, Router, RouterModule} from '@angular/router'; //import router
import { CartService } from '@shared/services/cart.service';


@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss']
})
export class NavigationBarComponent implements OnInit {
  cartItemNum = 0;

  details1=[
    {
      index:1,
      text: "",
      image: "assets/image/icon/fb.png",
      url:"https://www.facebook.com/chezchouxhongkong/",
      target: "_blank"
    },
    {
      index:2,
      text: "",
      image: "assets/image/icon/ig.png",
      url:"https://www.instagram.com/chezchoux/?utm_source=ig_embed",
      target: "_blank"
    },
    {
      index:3,
      text: "聯絡我們",
      image: "assets/image/icon/telephone.png",
      url:"/#/contact-us",
    },
    {
      index:4,
      text: "分店位置",
      image: "assets/image/icon/home.png",
      url:"/#/posts/store-location",
    }
  ]
  details2=[
    {
      index:1,
      text: "聯繫我們",
      image: "../../../assets/image/navigation-bar/layer-37.jpg",
      url:"/#/contact-us",
    },
  ]

  details3=[
    {
      index:1,
      textCn:"西樹泡芙",
      textEn:"Puff",
      image: "assets/image/icon/puff.png",
      url:"/#/products/40",
    },
    {
      index:2,
      textCn:"",
      textEn:"MOCHI",
      image: "assets/image/icon/mochi-icon.png",
      url:"/#/products/39",
    },
    {
      index:3,
      textCn:"蛋糕",
      textEn:"Cake",
      image: "assets/image/icon/cake-icon.png",
      url:"/#/products/43",
    },
    {
      index:4,
      textCn:"季節性產品",
      textEn:"Seasonal",
      image: "assets/image/icon/seasonal-icon.png",
      url:"/#/products/44",
    },
    {
      index:5,
      textCn:"推廣產品",
      textEn:"Promotional",
      image: "assets/image/icon/promotional-icon.png",
      url:"/#/products/88",
    },
    {
      index:6,
      textCn:"散水餅套餐",
      textEn:"Party Set",
      image: "assets/image/icon/party-set-icon.png",
      url:"/#/products/87",
    },
    {
      index:7,
      textCn:"禮劵",
      textEn:"Coupon",
      image: "assets/image/icon/coupon-icon.png",
      url:"/#/products/89",
    },
  ]

  showSideNavbar() {
    this.commonService.changeSideNavbarIsShow(true)
  }
  idName: any;
  private sub: any;
  isMobile = false;
  componentName: string;
  href: string;
  // YAS IT CAN DELAY TIME WOOOOOOOOOOOO
  private delay(ms: number)
  {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  public async refresh(){
    await this.delay(50);
    this.href = window.location.href;
    this.componentName= this.href.split("/").pop();

    console.log("the component url is ", this.href);
    console.log("the component name is ", this.componentName);
  }


  constructor(private commonService: CommonService,public breakpointObserver: BreakpointObserver, private route: ActivatedRoute , private router : Router, private cartService: CartService) { }

  ngOnInit(): void {
    this.breakpointObserver.observe(['(min-width: 992px)']).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    });

    this.cartService.shoppingCart$.subscribe((shoppingCart) => {
      if(shoppingCart.cartItems && shoppingCart.cartItems.length > 0) {
        this.cartItemNum = shoppingCart.cartItems.map(item => item.quantity).reduce((a,b) => a + b)
      }else {
        this.cartItemNum = 0;
      }
    })

    //reading route parameters
    this.sub = this.route.paramMap.subscribe(paramMap => {
      console.log(this.idName);
      this.idName = +paramMap.get('idName');
    });
    this.href = window.location.href;
    this.componentName= this.href.split("/").pop();

    console.log("the component url is ", this.href);
    console.log("the component name is ", this.componentName);

  }

}
