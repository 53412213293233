export class ContactUsRequest {
    name: string;
    email: string;
    phone: string;
    subject: string;
    orderNo: string;
    message: string;
    mid: string;

    constructor(contactUsRequest?) {
        contactUsRequest = contactUsRequest || {}
        this.name = contactUsRequest.name || ""
        this.email = contactUsRequest.email || ""
        this.phone = contactUsRequest.phone || ""
        this.subject = contactUsRequest.subject || ""
        this.orderNo = contactUsRequest.orderNo || ""
        this.message = contactUsRequest.message || ""
        this.mid = contactUsRequest.mid || "jw"
    }
}