import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ActivatedRoute } from '@angular/router';
import { Product } from '@shared/models/product.model';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {

  showcases: Product[] = [];

  isMobileMini = false;
  isMobile = false;
  isTablet=false;

  constructor( public breakpointObserver: BreakpointObserver, private route: ActivatedRoute) { }

  sortByDisplayOrder(a: Product, b: Product): number {
    let displayOrderA = (a.productSkus[0].productSkuEavs.find(eav => eav.attribute === "displayorder") || {}).value
    let orderA: number = 0;
    if(isNaN(parseInt(displayOrderA))) {
      orderA = 1
    }else {
      orderA = parseInt(displayOrderA)
    }
    let displayOrderB = (b.productSkus[0].productSkuEavs.find(eav => eav.attribute === "displayorder") || {}).value
    let orderB: number = 0;
    if(isNaN(parseInt(displayOrderB))) {
      orderB = 1
    }else {
      orderB = parseInt(displayOrderB)
    }

    return orderB - orderA;
  }

  ngOnInit(): void {

    this.route.data
      .subscribe((data: { showcase: any }) => {
        console.log('showcase', data.showcase.content)
        this.showcases = data.showcase.content
        this.showcases.sort((a, b) => this.sortByDisplayOrder(a, b))
      }) 

    this.breakpointObserver.observe(['(min-width: 991px)']).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    });
    this.breakpointObserver.observe(['(min-width: 600px)']).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.isMobileMini = false;
      } else {
        this.isMobileMini = true;
      }
    });
    this.breakpointObserver.observe(['(min-width: 1200px)']).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.isTablet = false;
      } else {
        this.isTablet = true;
      }
    });
  }

}
