<div class="bottom-footer-main-container">
  <!-- desktop mode -->
    <!-- main container -->
    <div class="top-container pt-2">
      <div class="top-wrapper" fxLayout="row wrap" fxLayoutAlign="center start">
        <!-- most left container -->
        <div fxLayout="row" fxLayoutAlign="start start" class="mw-50-mobile space-between-container-when-mobile">
          <div class = "leftmost-container hide-on-mobile">
            <img src="../../../../assets/image/bottom-footer/logo-copy.png">
          </div>
          <!-- second left container -->
          <div class="secondleft-container mt-2" fxLayout="column" >
            <a class="content" href="">
              關於我們
            </a>
            <a class="content" href="/#/posts/store-location">
              店面位置
            </a>
            <a class="content" href="/#/contact-us">
              聯繫我們
            </a>
          </div>
    
          <!-- middle container -->
          <div class="middle-container mt-2" fxLayout="column">
            <a class="content" href="/#/posts/terms-and-conditions">
              條款及條件
            </a>
            <a class="content" href="/#/posts/privacy-policy">
              私隱政策
            </a>
            <a class="content" href="/#/posts/cookies-policy">
              Cookie 政策
            </a>
            <a class="content" href="/#/posts/delivery-policy">
              送貨條款
            </a>
          </div>
        </div>

        <!-- rightmost-container -->
        <div fxLayout="row" fxLayoutAlign="end start" class="mw-30-mobile center-container-when-mobile">
          <div class="rightmost-container w-100">
            <div class="mb-1" fxLayout="row" fxLayoutAlign="start end">
              <img class="follow-us-icon" src="assets/image/icon/followus-icon.png">
              <a class="social-media-icon ml-1" href="https://www.facebook.com/chezchouxhongkong/" target="_blank"><img src="assets/image/icon/fb.png"></a>
              <a class="social-media-icon ml-1" href="https://www.instagram.com/chezchoux/?utm_source=ig_embed" target="_blank"><img src="assets/image/icon/ig.png"></a>
            </div>
            <mat-form-field class="w-100 subscribe-input" appearance="outline" floatLabel="always">
              <input [(ngModel)]="email" matInput placeholder="訂閱電子報"/>
              <img (click)="subscribeUs()" matSuffix src="assets/image/icon/arrow-icon.png" alt="">
            </mat-form-field>
            <p class="mt-1">如要取消訂閱，請把訂閱所登記的電郵地址及名字電郵至enews@chouxcreme.com.hk</p>
            <div class = "leftmost-container hide-on-desktop pt-2" fxLayout="row" fxLayoutAlign="center">
              <img src="assets/image/bottom-footer/logo-copy.png">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bottom-container">
      Copyright © {{year}} J-Sweets Co., Ltd.  All Rights Reserved. <span> | Powered by <a href="http://digidumpling.com" alt="DigiDumpling" style="text-decoration: underline; color:#000;">DigiDumpling</a></span>
      <br>
      食物製造廠牌照號碼 2997800209
    </div>
</div>
