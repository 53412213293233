import { Component, OnInit , Input , EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-quantity-button',
  templateUrl: './quantity-button.component.html',
  styleUrls: ['./quantity-button.component.scss']
})
export class QuantityButtonComponent implements OnInit {
  constructor() { }

  @Input() selectedQuantity: number;

  @Input() color: string = '#9c9c9c';

  @Output() addQuantityEvent = new EventEmitter<any>();

  @Output() subtractQuantityEvent = new EventEmitter<any>();

  @Output() keyEvent = new EventEmitter<any>();

  onAddQuantity($event){
    this.addQuantityEvent.emit($event)
  }
  onSubstractQuantity($event){
    this.subtractQuantityEvent.emit($event)
  }
  ngOnInit(): void {
  }
  onKey($event){
    this.keyEvent.emit($event)
  }

}
