import { Component, OnInit } from '@angular/core';
import { ProductSku } from '@shared/models/product-sku.model';
import { CommonService } from '@shared/services/common.service';

@Component({
  selector: 'app-product-popup',
  templateUrl: './product-popup.component.html',
  styleUrls: ['./product-popup.component.scss']
})
export class ProductPopupComponent implements OnInit {

  show = true

  productSku: ProductSku

  components: string[] = []

  constructor(private commonService: CommonService) { }

  ngOnInit(): void {
    this.commonService.showProductPopup$.subscribe((show) => {
      this.show = show
    })

    this.commonService.productToPopup$.subscribe(sku => {
      this.productSku = sku
      const desc: string = (sku.productSkuEavs.find(eav => eav.attribute === "description") || {value: ""}).value
      this.components = desc.split("\n")
    })
  }

  close() {
    this.commonService.closeProductPopup()
  }

}
