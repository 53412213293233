import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { map, take } from 'rxjs/operators';

import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';

import { ProductsService } from '@shared/services/products.service';

@Injectable({
  providedIn: 'root'
})
export class HomePageResolver implements Resolve<any>{

  constructor(private router: Router, private productService: ProductsService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.productService.getHomePageProductList().pipe(
      take(1),
      map(res => {
        if (res) {
          return res;
        } else {
          this.router.navigate(['/home']);
          return null;
        }
      })
    )
  }

}
