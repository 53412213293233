import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { SlidersService } from '@shared/services/sliders.service';
import Slider from '@shared/models/Slider';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})

export class HeaderComponent implements OnInit {

  desktopSlider: Slider = new Slider()
  mobileSlider: Slider = new Slider()

  slideConfig = {
    "arrows": false,
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "infinite": true,
    "speed": 500,
    "autoplay":true,
    "autoplaySpeed": 2000,
    "dots": true
  }
  slickInit(e) {
    console.log('slick initialized');
  }

  breakpoint(e) {
    console.log('breakpoint');
  }

  afterChange(e) {
    console.log('afterChange');
  }

  beforeChange(e) {
    console.log('beforeChange');
  }

  isMobile=false;

  isMobileMini=false;
  constructor(public breakpointObserver: BreakpointObserver, private slidersService: SlidersService) { }

  ngOnInit(): void {
    this.breakpointObserver.observe(['(min-width: 992px)']).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    });

    this.slidersService.getAllSlider().subscribe(rsp => {
      let sliders: Slider[] = rsp

      console.log('all sliders response', sliders)

      this.desktopSlider = sliders.filter(slider => slider.name === "Homepage Banner Desktop").length > 0 ? sliders.filter(slider => slider.name === "Homepage Banner Desktop")[0] : new Slider()
      this.mobileSlider = sliders.filter(slider => slider.name === "Homepage Banner Mobile").length > 0 ? sliders.filter(slider => slider.name === "Homepage Banner Mobile")[0] : new Slider()
    })
  }

}
