
<section id="strength-container">
  <h1 class="title">京粵會的願景</h1>
  <p class= "content">
    讓會員達成
    財富與健康的富足。推進高科技
    創新專案，帶來高品質的生活體驗
  </p>
  <h1 class="title">京粵會的使命</h1>
  <p class= "content"style="padding-bottom: 10px;">
    推進科技，創富與生活為核心，
    打造一站式的綜合服務會員平臺。
  </p>
  <p class= "content" >
    提供獨特和多元化的活動，
    讓會員率先享受優質品味生活。
  </p>

  <div id="grid-container">
    <div class="app-strength" [strength]="s" *ngFor="let s of strengths"></div>
  </div>



</section>
