import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { NavigationStart, Router } from '@angular/router';
import { ConfigurationService } from '@shared/services/config.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'jsweet-web';
  hitFooter = false
  hitFooterMobile = false
  onActivate(event) {
    window.scroll(0,0);
  }
  isMobile=false;

  isMobileMini=false;

  showCartIcon = true;

  constructor(public breakpointObserver: BreakpointObserver, private router: Router, private configurationService: ConfigurationService) { 
  }

  ngOnInit(): void {
    this.breakpointObserver.observe(['(min-width: 992px)']).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    });

    this.router.events.subscribe((e) => {
      if (e instanceof NavigationStart) {
        if(e.url.indexOf("cart") >= 0) {
          this.showCartIcon = false;
        }else {
          this.showCartIcon = true;
        }
      }
    })
  }
  @HostListener("window:scroll",[]) onWindowScroll() {
    // console.log('window.pageYoffset', window.pageYOffset)
    // console.log('window.innerHeight', window.innerHeight)
    // console.log('document.documentElement.scrollHeight', document.documentElement.scrollHeight)
    // console.log("current pos",document.documentElement.scrollHeight-window.pageYOffset - window.innerHeight)
    //most specific case
    if (this.isMobile==true && (document.documentElement.scrollHeight-window.pageYOffset - window.innerHeight < 830)){
      this.hitFooterMobile=true;
    }
    else if (this.isMobile==true && (document.documentElement.scrollHeight-window.pageYOffset - window.innerHeight >= 830)){
      this.hitFooterMobile=false;
    }
    if(document.documentElement.scrollHeight-window.pageYOffset - window.innerHeight < 370){
      this.hitFooter = true;
    }
    else if (document.documentElement.scrollHeight-window.pageYOffset - window.innerHeight >= 370){
      this.hitFooter=false;
    }



  }
}
