
<div class="app-container">
  <app-message-bar></app-message-bar>
  <app-side-navbar></app-side-navbar>
  <app-navigation-bar></app-navigation-bar>
  <router-outlet (activate)="onActivate($event)"></router-outlet>

  <ng-container *ngIf="isMobile == false && hitFooter==false && showCartIcon===true">
    <app-cart-icon class="normal"></app-cart-icon>
  </ng-container>

  <ng-container *ngIf="isMobile == false && hitFooter==true && showCartIcon===true">
    <app-cart-icon class="follow"></app-cart-icon>
  </ng-container>

  <ng-container *ngIf="isMobile == true && hitFooterMobile==false && showCartIcon===true">
    <app-cart-icon class="normal-mobile"></app-cart-icon>
  </ng-container>

  <ng-container *ngIf="isMobile == true && hitFooterMobile==true && showCartIcon===true">
    <app-cart-icon class="follow-mobile"></app-cart-icon>
  </ng-container>


  <app-bottom-footer></app-bottom-footer>
  <app-loading-page></app-loading-page>
  <app-product-popup></app-product-popup>
</div>
