<div class="navigation-bar-container pt-2 pb-1" fxLayout=" column ">

  <!-- desktop mode -->
  <ng-container *ngIf="!isMobile">
    <!-- top part  -->
    <div
      class="top-container m-vertical-2"
      fxLayout="row"
      fxLayoutAlign="space-between end"
    >
      <!-- left -->
      <div class="leftmost-container ml-2 mb-1">
        <div class="side-container" *ngFor="let item of details1">
          <a href="{{ item.url }}" target="{{item.target}}" class="item-image">
            <img src="{{ item.image }}" />
          </a>
          <a class="item-text" href="{{ item.url }}">
            {{ item.text }}
          </a>
        </div>
      </div>

      <!-- middle -->
      <div class="middle-container">
        <a href="/#/home-page">
          <img src="assets/image/icon/logo.png" />
        </a>
      </div>

      <!-- right -->
      <div class="rightmost-container mr-2 mb-1" fxLayout="row" fxLayoutAlign="end start">
        <a href="/#/checkout/cart">
          <img src="assets/image/icon/shopping-bag-icon.svg" />
        </a>
        <div *ngIf="cartItemNum > 0" class="cart-item-num">{{cartItemNum}}</div>
      </div>
    </div>

    <!-- bottom part  -->
    <div
      class="bottom-container"
      fxLayout="row"
      fxLayoutAlign="start start"
    >
      <ng-container *ngFor="let item of details3">
        <!-- item -->
        <a
          href="{{ item.url }}"
          class="item-container"
          fxLayout="row"
          fxLayoutAlign="start start"
        >
          <!-- item image -->
          <div class="item-image">
            <img src="{{ item.image }}" />
          </div>
          <div class="green-hover" fxLayout="column" >
            <!-- item content -->
            <div class="item-content">
              {{ item.textCn }}
            </div>
            <div class="item-content en">
              {{ item.textEn }}
            </div>
          </div>
        </a>
      </ng-container>
    </div>
  </ng-container>

  <!-- mobile mode -->
  <ng-container *ngIf ="isMobile">
    <div
    class="top-container"
    fxLayout="row"
    fxLayoutAlign="space-between start"
    >
      <!-- left side -->
      <div class="logo-left" >
        <a href="/#/home-page">
          <img class="company-logo" src="../../../../assets/image/navigation-bar/logo.jpg" />
        </a>
      </div>
      <!-- right side -->
      <div class="logo-right">
        <a style="z-index: 5000; " (click)="showSideNavbar()"><img class="hamburger"
          src="../../../../assets/image/navigation-bar/lnr-menu.svg" /></a>
      </div>

    </div>
  </ng-container>

</div>
