import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from './views/home-page/home-page.component'
import { ActivitiesPageComponent } from './views/activities-page/activities-page.component'
import { ContactUsComponent } from './views/contact-us/contact-us.component';
import { HomePageResolver } from './views/home-page/home-page.resolver';


const routes: Routes = [
  {
    path: '', redirectTo: 'home-page', pathMatch: 'full'
  },
  {
    path: 'home-page',
    component: HomePageComponent,
    loadChildren:() => import('./views/home-page/home-page.module').then(m=>m.HomePageModule),
    data:{preload: true},
    resolve: {
      showcase: HomePageResolver
    }
  },
  {
    path : 'activities-page',
    component: ActivitiesPageComponent,
    loadChildren:() => import ('./views/activities-page/activities-page.module').then(m=>m.ActivitiesPageModule),
  },

  {
    path: 'contact-us',
    component: ContactUsComponent,
    loadChildren:() => import('./views/contact-us/contact-us.module').then(m=>m.ContactUsModule),
    data:{preload: true}
  },
  {
    path: 'products',
    loadChildren:() => import('./views/products/products.module').then(m => m.ProductsModule),
    data: { preload: true }
  },
  {
    path: 'checkout',
    loadChildren:() => import('./views/checkout/checkout.module').then(m => m.CheckoutModule),
    data: { preload: true }
  },
  {
    path: 'posts',
    loadChildren:() => import('./views/posts/posts.module').then(m => m.PostsModule),
    data: { preload: true }
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
