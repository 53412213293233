import { NgModule } from "@angular/core";

import { CommonModule } from "@angular/common";

import { LayoutModule } from '@angular/cdk/layout';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';


// LIBRARY
import { SlickCarouselModule } from "ngx-slick-carousel";
import { CountoModule } from "angular2-counto";
import { FlexLayoutModule } from "@angular/flex-layout";

// MATERIAL-UI
import { MaterialModule } from "../../material.module";

// COMPONENTS
import { HeaderComponent} from "./components/header/header.component";
import { ExperienceComponent } from "./components/experience/experience.component";
import { FooterComponent } from "./components/footer/footer.component";
import { StrengthComponent } from "./components/strength/strength.component";
import { StrengthContainerComponent } from "./components/strength-container/strength.container.component";
import { NavigationBarComponent } from "./components/navigation-bar/navigation-bar.component";
import { BottomFooterComponent } from "./components/bottom-footer/bottom-footer.component";
import { SideBannerComponent } from "./components/side-banner/side-banner.component";
import { SideNavbarComponent } from "./components/side-navbar/side-navbar.component";
import { MessageBarComponent } from "./components/message-bar/message-bar.component";
import { ShowcaseItemComponent } from "./components/showcase-item/showcase-item.component";
import { QuantityButtonComponent } from "./components/quantity-button/quantity-button.component";
import { CartIconComponent } from "./components/cart-icon/cart-icon.component";
import { SectionTitleComponent } from "./components/section-title/section-title.component";
import { LoadingPageComponent } from './components/loading-page/loading-page.component';
import { ShoppingCartTableComponent } from './components/shopping-cart-table/shopping-cart-table.component';
import { CheckoutQuantityButtonComponent } from './components/checkout-quantity-button/checkout-quantity-button.component';
import { UnderlineTitleComponent } from './components/underline-title/underline-title.component';
import { ProductPopupComponent } from './components/product-popup/product-popup.component';
import { CouponSectionComponent } from './components/coupon-section/coupon-section.component';
import { InputWithButtonComponent } from './components/input-with-button/input-with-button.component';

// SERVICES
import { HttpService } from './services/http.service';

//PIPES
import { RelativeTimePipe } from "./pipes/relative-time.pipe";
import { ExcerptPipe } from "./pipes/excerpt.pipe";
import { GetValueByKeyPipe } from "./pipes/get-value-by-key.pipe";
import { GetValueByAttributePipe } from "./pipes/get-value-by-attribute.pipe";
import { GetImagePathByAttributePipe } from "./pipes/get-image-path-by-type.pipe";
import { GetProductSalesPriceIfAny } from "./pipes/get-product-sales-price-if-any";
import { CheckProductIsSale } from "./pipes/check-product-is-sale";

const classesToInclude = [
  HeaderComponent,
  ExperienceComponent,
  FooterComponent,
  StrengthComponent,
  StrengthContainerComponent,
  NavigationBarComponent,
  BottomFooterComponent,
  SideBannerComponent,
  SideNavbarComponent,
  MessageBarComponent,
  ShowcaseItemComponent,
  QuantityButtonComponent,
  CartIconComponent,
  SectionTitleComponent,
  LoadingPageComponent,
  ShoppingCartTableComponent,
  CheckoutQuantityButtonComponent,
  UnderlineTitleComponent,
  ProductPopupComponent,
  RelativeTimePipe,
  ExcerptPipe,
  GetValueByKeyPipe,
  GetValueByAttributePipe,
  GetImagePathByAttributePipe,
  GetProductSalesPriceIfAny,
  CheckProductIsSale,
  CouponSectionComponent,
  InputWithButtonComponent,
];

const modulesToImportExport = [
  MaterialModule,
  SlickCarouselModule,
  CountoModule,
  FlexLayoutModule,
  FormsModule,
  ReactiveFormsModule
];

const serviceToProcide = [
  HttpService,
]

@NgModule({
  declarations: classesToInclude,
  imports: [CommonModule, LayoutModule, FormsModule, modulesToImportExport],
  providers: [serviceToProcide],
  exports: [modulesToImportExport, classesToInclude],
})
export class SharedModule {}
