export class Address {
    id: number;
    regionCode: string;
    firstname: string;
    middlename: string;
    lastname: string;
    address: string;
    country: string;
    city: string;
    region: string;
    postalCode: string;
    type: string; // SHIPPING, SHIPPING, PICKUP, SF, BILLING
    default: boolean;

    constructor(type, obj: Address) {
        if(obj) {
            this.id = obj.id
            this.regionCode = obj.regionCode
            this.firstname = obj.firstname
            this.middlename = obj.middlename
            this.lastname = obj.lastname
            this.address = obj.address
            this.country = obj.country
            this.city = obj.city
            this.region = obj.region
            this.postalCode = obj.postalCode
            this.type = type
            this.default = obj.default
        }else {
            this.regionCode = "852"
            this.country = "Hong Kong";
            this.postalCode = "000000"
            this.type = type;
        }
    }
}
