import { Injectable } from '@angular/core';
import { ProductSku } from '@shared/models/product-sku.model';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  sideNavbarIsShowSource = new BehaviorSubject(false);
  sideNavbarIsShow = this.sideNavbarIsShowSource.asObservable()

  private showLoadingPageSource = new BehaviorSubject(false);
  showLoadingPage = this.showLoadingPageSource.asObservable()

  private showProductPopupBS = new BehaviorSubject(false);
  showProductPopup$ = this.showProductPopupBS.asObservable()

  private productToPopupBS = new BehaviorSubject<ProductSku>(new ProductSku());
  productToPopup$: Observable<ProductSku> = this.productToPopupBS.asObservable()

  startLoading() {
    this.showLoadingPageSource.next(true)
  }

  stopLoading() {
    this.showLoadingPageSource.next(false)
  }

  openProductPopup() {
    this.showProductPopupBS.next(true)
    let body = document.getElementsByTagName('body')[0];
    body.classList.add('isPopup');
  }

  closeProductPopup() {
    this.showProductPopupBS.next(false)
    let body = document.getElementsByTagName('body')[0];
    body.classList.remove('isPopup');
  }

  setProduct(productSku: ProductSku) {
    this.productToPopupBS.next(productSku)
  }

  changeSideNavbarIsShow(isShow: boolean) {
    this.sideNavbarIsShowSource.next(isShow)
  }

  private popUpIsShowSource = new BehaviorSubject(false);
  popUpIsShow = this.popUpIsShowSource.asObservable()

  changePopUpIsShow(isShow: boolean) {
    this.popUpIsShowSource.next(isShow)
  }
  

  constructor() {}
}
