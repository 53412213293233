
<ng-container *ngIf="isMobile">
  <div [style]="
      show ? 'transform: translateX(0%)' : 'transform: translateX(150%)'
    " class="side-navbar-container">
    <div *ngIf="showSideNavbar" class="content">
      <a class="cross" (click)="hideSideNavbar()">
        <img src="../../../../assets/image/side-navbar/lnr-cross.svg" />
      </a>
      <div class="yellow-bar"></div>

      <div class="top-container">
        <div class="title-container">
          <a href="/#/home-page" (click)="hideSideNavbar()">
            <img class="scm_logo" src="../../../../assets/image/bottom-footer/logo-copy.png" alt="J-Sweets" />
          </a>
        </div>
        <div fxLayout="column" fxLayoutAlign="start start" class="menu-list w100">
          <ng-container *ngFor="let title of Titles">
              <div class="menu-big-item">
                <ng-container *ngIf="title.position != 7">
                  <a class="menu-item" (click)="hideSideNavbar()" href="{{title.link}}">
                      {{title.name}}
                  </a>
                </ng-container>
                <ng-container *ngIf="title.position == 7">
                  <a class="menu-item" style="border-bottom: none;" (click)="hideSideNavbar()" href="{{title.link}}">
                    {{title.name}}
                  </a>
                </ng-container>

              </div>
          </ng-container>

          <!-- <div  fxLayout="row" fxLayoutAlign="space-between start" class="mini-container"   >
            <div class="item-image">
              <img src="{{ item.image }}" />
            </div>
            <ng-container *ngFor="let item of details">
              <a class="item-text" href="{{ item.url }}" >
                {{ item.text }}
              </a>
            </ng-container>

          </div> -->
        </div>
        <div class="social-media" fxLayout="row" fxLayoutAlign="space-between center">
          <a href="#"><img src="../../../../assets/image/bottom-footer/icon-instagram-white.svg"/></a>
          <!-- <a href="#"><img src="../../../../assets/image/bottom-footer/-e-wechat.png"/></a> -->
          <a href="https://www.facebook.com"><img src="../../../../assets/image/bottom-footer/icon-facebook-white.svg" /></a>

        </div>
      </div>

    </div>
  </div>
</ng-container>
<!-- fix this later in desktop mode it disappear but other mode it reappear -->
<ng-container *ngIf="!isMobile">
  <!-- hideSideNavbar() -->
</ng-container>
