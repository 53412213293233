<div class="home-container">
  <app-header></app-header>

  <app-section-title tc="關 於 我 們" en ="ABOUT US"></app-section-title>

  <div class="about-us-container padding-top-4">
    <img class="left-image" src="assets/image/home-page/about-us-left-image.png" alt="">
    <div fxLayout="column" fxLayoutAlign="space-between center" class="right-container">
      <div fxLayout="row" fxLayoutAlign="space-between center" class="top-sub-container">
        <img src="assets/image/home-page/about-us-top-sub-container-1.png" alt="">
        <div class="divider"></div>
        <img src="assets/image/home-page/about-us-top-sub-container-2.png" alt="">
        <div class="divider"></div>
        <img src="assets/image/home-page/about-us-top-sub-container-3.png" alt="">
      </div>
      <p>
        這些年，在芭芙製作的過程中，我們積累了豐富的經驗和專業知識。
        不斷的學習，研發出最佳的烘焙技術，為大眾提供最酥脆鬆化的烘焙食品。
        我們採用新鮮、上等和時令的食材，創作出與眾不同的獨特餡料，別具一格，
        為客人帶來無窮滋味!
      </p>
    </div>
  </div>

  <app-section-title tc="人 氣 產 品" en ="BEST ITEM"></app-section-title>
  
  <div class="showcase-container">
    <!-- diff item part  -->
  
    <!-- desktop mode -->
    <ng-container>
      <div
      class="showcase-big-container"
      fxLayout="row wrap" fxLayoutAlign="center center"
      >
        <!-- display food items in showcase -->
        <ng-container *ngFor="let showcase of showcases">
          <div class="showcase-item-container" fxLayout="column" fxLayoutAlign="start start">
            <app-showcase-item class="showcase-item" [showcaseItem]="showcase" ></app-showcase-item>
          </div>
        </ng-container>
      </div>
    </ng-container>
  
    <!-- bottom part -->
    <div class="showcase-bottom" fxLayout="row" fxLayoutAlign="center center">
      <p style="color: #ffffff; padding: 0px; margin: 0px; display: none">babibabibabibabi</p>
      <a href="/#/products/40" class="title">
        更多餐目>>
      </a>
      <div class="ellipse">
        <img src="../../../assets/image/home-page/cart-icon.png">
      </div>
    </div>
  </div>
</div>
