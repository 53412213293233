import { Injectable } from "@angular/core";
import { SessionStoreService } from "../session-store.service";
import { Router, ActivatedRoute } from "@angular/router";
import { map, catchError } from "rxjs/operators";
import { of, BehaviorSubject, throwError, Observable } from "rxjs";

import { Customer } from '@shared/models/customer.model';

@Injectable({
  providedIn: "root",
})
export class JwtAuthService {
  USER = "USER";
  JWT_TOKEN = "JWT_TOKEN";
  GUEST_CART_ID = "GUEST_CART_ID"
  USER_CART_ID = "USER_CART_ID"

  token;
  isAuthenticated: Boolean;
  user: Customer = null;
  user$ = (new BehaviorSubject<Customer>(this.user));
  signingIn: Boolean;
  return: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private ss: SessionStoreService
  ) {
    this.route.queryParams
      .subscribe(params => this.return = params['return'] || '/');
  }

  public signOut() {
    console.log("signOut::start");
    this.setUserAndToken(null, null, null);
    this.ss.clear();
    // this.router.navigateByUrl("auth");
  }

  isLoggedIn(): Boolean {
    return this.getJwtToken() != null ? true : false;
  }

  getJwtToken() {
    return this.ss.getItem(this.JWT_TOKEN);
  }

  getUser(): Observable<Customer> {
    return of(this.ss.getItem(this.USER));
  }
  getUserFirstname(): Observable<string>{
    return of(this.ss.getItem(this.USER).firstname);
  }

  setUserAndToken(token: string, user: Customer, isAuthenticated: Boolean) {
    this.token = token;
    this.isAuthenticated = isAuthenticated;
    this.user$.next(user);
    this.ss.setItem(this.JWT_TOKEN, token);
    this.ss.setItem(this.USER, user);
  }

  setUser(user: Customer) {
    this.user$.next(user);
    this.ss.setItem(this.USER, user);
  }

  setToken(token: string, isAuthenticated: Boolean) {
    this.token = token;
    this.isAuthenticated = isAuthenticated;
    this.ss.setItem(this.JWT_TOKEN, token);
  }

  getUpdatedUser(): Observable<Customer> {
    return this.user$.asObservable()
  }

  // public signin(username, password) {
  //   return of({ token: DEMO_TOKEN, user: DEMO_USER })
  //     .pipe(
  //       delay(1000),
  //       map((res: any) => {
  //         this.setUserAndToken(res.token, res.user, !!res);
  //         this.signingIn = false;
  //         return res;
  //       }),
  //       catchError((error) => {
  //         return throwError(error);
  //       })
  //     );

  // FOLLOWING CODE SENDS SIGNIN REQUEST TO SERVER

  // this.signingIn = true;
  // return this.http.post(`${environment.apiURL}/auth/local`, { username, password })
  //   .pipe(
  //     map((res: any) => {
  //       this.setUserAndToken(res.token, res.user, !!res);
  //       this.signingIn = false;
  //       return res;
  //     }),
  //     catchError((error) => {
  //       return throwError(error);
  //     })
  //   );
  // }

  /*
    checkTokenIsValid is called inside constructor of
    shared/components/layouts/admin-layout/admin-layout.component.ts
  */
  // TODO: can call customer/me and return 200 to check
  // public checkTokenIsValid() {
  //   return of(this.user)
  //     .pipe(
  //       map((customer: Customer) => {
  //         this.setUserAndToken(this.getJwtToken(), profile, true);
  //         this.signingIn = false;
  //         return profile;
  //       }),
  //       catchError((error) => {
  //         return of(error);
  //       })
  //     );
  // }
}
