import { Component, OnInit,Input } from '@angular/core';
import { Product } from '@shared/models/product.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProductSku } from '@shared/models/product-sku.model';
import { SubmitCartItem } from '@shared/models/submit-cart-item.model';
import { CartService } from '@shared/services/cart.service';
import { CommonService } from '@shared/services/common.service';

@Component({
  selector: 'app-showcase-item',
  templateUrl: './showcase-item.component.html',
  styleUrls: ['./showcase-item.component.scss']
})
export class ShowcaseItemComponent implements OnInit {
  @Input() showcaseItem: Product;

  selectedQuantity: number = 1;

  constructor(
    private matSnackBar: MatSnackBar,
    private cartService: CartService,
    private commonService: CommonService) { }

  ngOnInit(): void {
  }
  onAddQuantity($event){
    this.selectedQuantity += 1;
  }
  onSubstractQuantity($event){
    if(this.selectedQuantity > 1){
      this.selectedQuantity -= 1;
    }
  }
  onKey($event: any){
    console.log("key", $event.target.valueAsNumber);
    this.selectedQuantity = $event.target.valueAsNumber;
  }

  showSnackBar(message: string) {
    this.matSnackBar.open(message, null, {
        duration: 2000,
    });
  }

  isOutOfStock(productSku: ProductSku): boolean {
    return productSku.stock <= productSku.minStockLevel
  }

  addToCart(productSku: ProductSku) {
    if(this.isOutOfStock(productSku)){
      console.log("sadasds");
      return
    }

    let submitCartItem = new SubmitCartItem()


    submitCartItem.setproductSku(productSku.id.toString())
    submitCartItem.setquantity(this.selectedQuantity.toString())

    try {
      this.cartService.addToCart(submitCartItem).subscribe(data => {
        this.cartService.setCart(data)
        this.showSnackBar("已加入購物籃")
      })
    } catch (error) {
      console.log("asdasdadadasdasdasd");
    }
    
  }

  isSalesPrice(productSku: ProductSku): boolean {
    if(productSku.salesFrom && productSku.salesTo && productSku.salesPrice) {
      let today = new Date().getTime() / 1000
      if(today > productSku.salesFrom && today < productSku.salesTo) {
        return true;
      }else {
        return false;
      }
    }else {
      return false;
    }
  }

  isPartySet(productSku: ProductSku): boolean {
    return productSku.productSkuEavs.findIndex(eav => eav.attribute === "description") >= 0 
    && (productSku.productSkuEavs.find(eav => eav.attribute === "description") || {value: ""}).value !== "0"
    && (productSku.productSkuEavs.find(eav => eav.attribute === "description") || {value: ""}).value !== ""
  }

  popup() {
    this.commonService.setProduct(this.showcaseItem.productSkus[0])
    this.commonService.openProductPopup()
  }
}
