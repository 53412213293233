import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { map, take } from 'rxjs/operators';

import { ProductSkuFilterRequest } from '@shared/models/request/product-sku-filter-request';

import { HttpService } from '@shared/services/http.service';
import {SessionStoreService} from "@shared/services/session-store.service";

const GET_PRODUCT_LIST = 'catalog/product';
const GET_PRODUCT_LIST_FILTER = 'catalog/product/filter';
@Injectable({
  providedIn: 'root'
})
export class ProductsService{

  constructor(private httpService: HttpService, private ss: SessionStoreService) { }

  getProductList(page: number = 0, sortBy: string = 'id', size: string = "6", order: string = 'DESC', filter: ProductSkuFilterRequest = new ProductSkuFilterRequest()): Observable<any> {
    const params = new HttpParams().set('page', page.toString())
      .set('size', size)
      .set('sort', sortBy)
      .set('direction', order);

    return this.httpService.put(GET_PRODUCT_LIST_FILTER, { params: params, body: filter });
  }

  getHomePageProductList(page: number = 0, sortBy: string = 'id', size: string = "6", order: string = 'DESC', filter: ProductSkuFilterRequest = new ProductSkuFilterRequest()): Observable<any> {
    filter.categoryIds.push(48)
    const params = new HttpParams().set('page', page.toString())
      .set('size', size)
      .set('sort', sortBy)
      .set('direction', order);

    return this.httpService.put(GET_PRODUCT_LIST_FILTER, { params: params, body: filter });
  }

  getProductDetail(productId: string): Observable<any> {
    const params = new HttpParams().set('id', productId);
    return this.httpService.get(GET_PRODUCT_LIST + "/" + productId, null);
  }

}
