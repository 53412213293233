import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from '@shared/services/config.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-message-bar',
  templateUrl: './message-bar.component.html',
  styleUrls: ['./message-bar.component.scss']
})
export class MessageBarComponent implements OnInit {

  message: string = ""

  // Private
  private _unsubscribeAll: Subject<any>;

  constructor(private configurationService: ConfigurationService) { 
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    // this.configurationService.configurations.pipe(takeUntil(this._unsubscribeAll)).subscribe((configs: any) => {
    //   console.log('checkcheckcheck')
    //   this.message = (configs.filter(config => config.attribute === "message_bar") || [{value: ""}])[0].value
    // })

    setTimeout(() => {
      this.configurationService.getAllConfig().subscribe(configs => {
        console.log('all configuration response', configs)
        this.message = configs.filter(config => config.attribute === "message_bar").length > 0 ? configs.filter(config => config.attribute === "message_bar")[0].value : ""
      })
    }, 1000)
  }

}
