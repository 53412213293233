<div class="section-container mt-4" fxLayout="row" fxLayoutAlign="start center">
    <div class="divider"></div>
    <div fxLayout="row" fxLayoutAlign="center center" class="section-title">
        <img style="width: 40px;" *ngIf="imagePrefixPath" [src]="imagePrefixPath" alt="">
        <div fxLayout="column" fxLayoutAlign="center center">
            <div class="tc-content">{{tc}}</div>
            <div class="en-content">{{en}}</div>
        </div>
    </div>
    <div class="divider"></div>
</div> 
