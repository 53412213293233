<section id="experience">
  <ng-container *ngIf="!isMobile">
    <div class="main-container" fxLayout="row" fxLayoutAlign="start center">

      <div class="image-container" fxFlex="60">
        <img src="../../../../assets/image/home-page/layer-sea.jpg">
      </div>
      <div class ="content-container" fxFlex =" 40">
        <h1 class="title-container">會員尊屬禮遇及體驗</h1>
        <p class="description">
          會員可根據自己的喜好可以選擇加入<活動系列>
          並享受獨特禮遇及體驗!最新未來科技體驗、環球智能生活與高科技健康資訊講座及分享會、
          智能生活項目投資機會、另類投資項目:加密貨幣、智慧生活、人工智能
        </p>
        <button>更多活動</button>
      </div>

    </div>
  </ng-container>
  <ng-container *ngIf="isMobile">
    <div class="main-container" fxLayout="column" fxLayoutAlign="start center">

      <div class="image-container" >
        <img src="../../../../assets/image/home-page/layer-sea.jpg">
      </div>
      <div class ="content-container">
        <h1 class="title-container">會員尊屬禮遇及體驗</h1>
        <p class="description">
          會員可根據自己的喜好可以選擇加入<活動系列>
          並享受獨特禮遇及體驗!最新未來科技體驗、環球智能生活與高科技健康資訊講座及分享會、
          智能生活項目投資機會、另類投資項目:加密貨幣、智慧生活、人工智能
        </p>
        <button>更多活動</button>
      </div>

    </div>
  </ng-container>
</section>
