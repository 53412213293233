import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cart-icon',
  templateUrl: './cart-icon.component.html',
  styleUrls: ['./cart-icon.component.scss']
})
export class CartIconComponent implements OnInit {
  //current scroll location
  scrollPosition= window.pageYOffset + window.innerHeight; //max should equal to total scroll height
  maxWindowHeight= document.documentElement.scrollHeight; //total allowable height

  hitFooterMobile = false
  hitFooter = false

  isMobile=false;

  isMobileMini=false;
  constructor(public breakpointObserver: BreakpointObserver, private router: Router) { }
  ngOnInit(): void {
    this.breakpointObserver.observe(['(min-width: 992px)']).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    });
  }

  @ViewChild("cartIcon") cartIcon: ElementRef

  @HostListener("window:scroll",[]) onWindowScroll() {
    // console.log('window.pageYoffset', window.pageYOffset)
    // console.log('window.innerHeight', window.innerHeight)
    // console.log('document.documentElement.scrollHeight', document.documentElement.scrollHeight)
    // console.log('cartIcon', this.cartIcon.nativeElement.offsetTop)
    // console.log("current pos",document.documentElement.scrollHeight-window.pageYOffset - window.innerHeight)

    if (this.isMobile==true && (document.documentElement.scrollHeight-window.pageYOffset - window.innerHeight < 830)){
      this.hitFooterMobile=true;
    }
    else if (this.isMobile==true && (document.documentElement.scrollHeight-window.pageYOffset - window.innerHeight >= 830)){
      this.hitFooterMobile=false;
    }
    if(document.documentElement.scrollHeight-window.pageYOffset - window.innerHeight < 370){
      this.hitFooter = true;
    }
    else if (document.documentElement.scrollHeight-window.pageYOffset - window.innerHeight >= 370){
      this.hitFooter=false;
    }

  }

  goToShoppingCart() {
    this.router.navigate(['/checkout/cart']);
  }


}
