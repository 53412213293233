<div class="contact-us-container mt-4-desktop-only">
    <div class="contact-us-wrapper">
        <app-section-title tc="聯 絡 我 們" en ="CONTACT US"></app-section-title>
        <div class="info padding-top-4">
            我們致力為客人提供優質的食品及服務，歡迎客人提供寶貴意見，讓我們能夠做得更好。<br>
            如有任何業務、合作查詢，歡迎跟我們聯絡 info@chouxcreme.com.hk<br>
            或於辦公時間內致電：(852) 2690 1853與我們聯繫。
        </div>
        <div fxLayout="column" fxLayoutAlign="start center" class="contact-us-form padding-top-4  padding-bottom-8">
            <mat-form-field appearance="outline" class="w-60">
                <mat-label>姓名</mat-label>
                <input [(ngModel)]="contactUsRequest.name" matInput placeholder="姓名" required>
            </mat-form-field>

            <mat-form-field appearance="outline" class="w-60">
                <mat-label>電郵地址</mat-label>
                <input [(ngModel)]="contactUsRequest.email" matInput placeholder="電郵地址" required>
            </mat-form-field>

            <mat-form-field appearance="outline" class="w-60">
                <mat-label>聯絡電話</mat-label>
                <input [(ngModel)]="contactUsRequest.phone" matInput placeholder="聯絡電話" required>
            </mat-form-field>

            <mat-form-field appearance="outline" class="w-60">
                <mat-label>留言</mat-label>
                <textarea [(ngModel)]="contactUsRequest.message" matInput required rows="10"></textarea>
            </mat-form-field>

            <div class="form-hint">
                <span>* 必須填寫</span>
            </div>

            <div (click) = "sendContactUsEmail()" fxLayout="column" fxLayoutAlign="center center" class="form-button margin-top-4">
                <span>送出</span>
            </div>
        </div>
    </div>
</div>
