import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpHeaders,
  HttpErrorResponse
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { environment } from "@env/environment";
import { catchError, map } from "rxjs/operators";
import { CommonService } from '@shared/services/common.service';
import Swal from 'sweetalert2';

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
  constructor(
    private appService: CommonService,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = localStorage.getItem("token");
    const tokenHeader = token ? { Authorization: `Bearer ${token}` } : {};

    const originalHeaders = {};
    req.headers
      .keys()
      .forEach((key) => (originalHeaders[key] = req.headers.get(key)));

    const headers: HttpHeaders = new HttpHeaders({
      // "Content-type": "application/json",
      "Merchant-Id": environment.merchantId,
      "Accept-Language": 'zh-HK',
      ...tokenHeader,
      ...originalHeaders
    });

    const newReq = req.clone({
      url: `${req.url}`,
      headers
    })

    this.appService.startLoading();

    return next.handle(newReq).pipe(
      map((e: HttpEvent<any>) => {
        console.log(e);
        if (e.type != 0) {
          window.scroll(0, 0)
          this.appService.stopLoading();
          Swal.close()
        }
        return e;
      }),
      catchError((err: HttpErrorResponse) => {
        window.scroll(0, 0)
        this.appService.stopLoading();
        Swal.close()

        // TODO: pass error msg to show in messageDialog
        // const dialogConfig = new MatDialogConfig();
        // dialogConfig.disableClose = true;
        // dialogConfig.autoFocus = true;

        // this.dialog.open(MessageDialogComponent, dialogConfig);
        console.log("err::" + JSON.stringify(err))
        if (err.error.error != "Element does not exists") {
          alert(err.error.error);
        }

        return throwError(err);
      })
    );
  }
}
